.wrapper {
  background-color: var(--list-property-page-background-value-prop);
}

.container {
  --container-max-width: auto;
  --container-padding: var(--space-16x) var(--space-6x);

  @media (--screen-md) {
    --container-max-width: 976px;
  }
}

.heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--space-5x);
  width: 100%;
  margin: 0 auto;
  --brand-divider-margin: 0;

  & p {
    color: var(--color-gray-500);
  }

  @media (--screen-md) {
    width: 70%;
  }
}

.subTitle {
  font-size: var(--font-size-body);
}

.list {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
  gap: var(--space-8x);
  padding: var(--list-property-value-props-padding);

  @media (--screen-md) {
    gap: var(--space-8x);
    flex-direction: row;
    align-items: flex-start;
  }

  .listItem {
    text-align: center;
  }

  .icon {
    position: relative;
    border-radius: 50%;
  }

  .listItemContent {
    margin: var(--space-6x) auto 0;
    font-size: var(--value-prop-content-font-size);

    @media (--screen-md) {
      max-width: 180px;
    }
  }
}
