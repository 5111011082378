.container {
  background: var(--background);
  color: var(--color);
  line-height: var(--line-height);
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --button-gap: var(--space-1-5x);
  font-size: var(--font-size-body);
}

.container img {
  display: inline-block;
}
