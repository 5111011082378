.brands {
  --list-property-block-padding: var(--space-14x) var(--space-6x);
  --list-property-block-headline-margin-bottom: var(--space-3x);
  background-color: var(--list-property-page-background);

  @media (--screen-md) {
    --list-property-block-wrapper-gap: var(--space-8x);
    --list-a-property-text-image-block-container-width: 454px;
    --list-property-block-padding: 100px 0;
  }
}
