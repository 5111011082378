.layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--space-5x);
  margin: 0 auto;
  --button-margin: var(--space-3-5x) 0 0 0;

  & p {
    color: var(--color-gray-500);
  }

  @media (--screen-md) {
    width: 70%;
  }
}

.heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 17px;
}

.content {
  font-size: var(--font-size-body);
}

.pentagonIconColor {
  color: var(--color-red-500);
}
