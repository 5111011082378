.logos {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: var(--list-property-logos-margin);

  @media (--screen-md) {
    justify-content: flex-start;
  }
}

.row {
  align-items: center;
  gap: var(--space-6x);
}

.column {
  flex-direction: column;
  gap: var(--list-property-logos-gap);
}
