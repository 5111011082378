.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--list-property-block-padding);

  @media (--screen-md) {
    flex-direction: row;
    gap: var(--list-property-block-wrapper-gap);
  }
}

.container {
  display: flex;
  flex-direction: column;
  margin: 0;
  text-align: center;
  width: var(--list-a-property-text-image-block-container-width);

  @media (--screen-md) {
    text-align: left;
  }
}

.icon {
  color: var(--color-red-600);
  margin: 0 auto var(--space-3x);

  @media (--screen-md) {
    margin: 0 0 var(--space-3x);
  }
}

.subHeadline {
  color: var(--color-black);
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-semibold);

  @media (--screen-md) {
    font-size: var(--font-size-4xl);
  }
}

.headline {
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-body);
  color: var(--color-gray-500);
  margin-bottom: var(--list-property-block-headline-margin-bottom);
}

.image {
  object-fit: cover;
  object-position: center;
  border-radius: 15px;
  margin: 0 auto var(--space-8x);
  max-width: 100%;
  width: 100%;
  height: 181px;

  @media (--screen-sm) {
    height: 254px;
  }

  @media (--screen-md) {
    height: 368px;
    max-width: 488px;
    margin: 0;
  }

  @media (--screen-lg) {
    max-width: 576px;
  }
}

.sideImage {
  display: none;

  @media (--screen-md) {
    display: block;
  }
}

.inlineImage {
  display: block;

  @media (--screen-md) {
    display: none;
  }
}

.button {
  margin-bottom: var(--space-8x);
}
